.messages {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  scrollbar-width: none;
}
.systemMessage {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
  background: #267c93;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
  font-weight: 500;
}
